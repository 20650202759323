import arabic from '@/locales/ar.json';
import english from '@/locales/en.json';
export default defineI18nConfig(() => ({
   legacy: false,
   locale: useCookie('i18n_redirected').value || 'en',
   messages: {
      en: english,
      ar: arabic,
   },
}));
